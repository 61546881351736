.application-wrapper {
	padding: 80px 24px;
	background: #fbfdfc;
	overflow: hidden;
}

.application {
	max-width: 1200px;
	margin: auto;
	display: grid;
	grid-template-columns: 60fr 40fr;
}

@media screen and (max-width: 768px) {
	.application {
		grid-template-columns: auto;
	}

	.application .text * {
		text-align: left;
	}
}

.application .text {
	display: grid;
	gap: 30px;
	align-content: center;
	justify-items: start;
}

.application .text p {
	max-width: 70%;
}

.application .text .hiring-button {
	display: block;
}
.application .mockup .hiring-button {
	display: none;
}

.mockup img {
	margin-top: 30px;
	margin-bottom: 30px;
	left: 100px;
	position: relative;
}

@media screen and (max-width: 768px) {
	.application .text .hiring-button {
		display: none;
	}
	.application .mockup .hiring-button {
		display: block;
	}
}

.center-button {
	display: flex;
	justify-content: center;
}
