.hero {
	max-width: 1200px;
	margin: auto;
}

.hero-wrapper {
	padding: 0 24px;
}

@media screen and (max-width: 768px) {
	.hero {
		padding: 20px 14px 0 14px;
	}
}

.hero-grid {
	display: grid;
	grid-template-columns: 6fr auto;
	align-items: flex-start;
	justify-items: stretch;
	gap: 20px 80px;
}

@media screen and (max-width: 768px) {
	.hero-grid {
		grid-template-columns: 1fr;
	}
}

.align-end {
	align-self: end;
	justify-self: center;
}

.hero-illustration {
	width: 310px;
	max-width: 100%;
	height: 500px;
	object-fit: cover;
	object-position: top;
}

p.pill {
	display: inline-block;
	background: hsl(166, 59%, 92%);
	border-radius: 100px;
	padding: 2px 10px;
	margin-bottom: 20px;
}

.hero h1 {
	text-align: left;
	margin-bottom: 0.3em;
	max-width: 600px;
}

p.subheading {
	max-width: 450px;
	margin-bottom: 20px;
}

.desktop-spacer {
	display: block;
	height: 100px;
}

@media screen and (max-width: 768px) {
	.desktop-spacer {
		display: none;
	}
}
