.process-wrapper {
	background: #a1e7d766;
	padding: 80px 24px;
	display: flex;
	justify-content: center;
}

.process {
	max-width: 1000px;
}

.process .flex {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	gap: 50px;
	margin: 40px 0;
}

.process .point {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	max-width: 220px;
}

.process h2,
.process h3,
.process p {
	text-align: center;
}
