html {
  scroll-behavior: smooth;
}

.next-order-form {
	display: grid;
	grid-template-columns: calc(100% - 350px - 24px) 350px;
	gap: 24px;
}

@media screen and (max-width: 768px) {
	.next-order-form {
		display: flex;
		gap: unset;
	}
}

.next-order-form .form-body {
	width: 100%;
	grid-row: 1;
	grid-column: 1;
}

.next-order-form .form-aside {
	width: 350px;
	grid-row: 1;
	grid-column: 2;
}

.label {
	vertical-align: top;
	font-weight: bold;
}

.input-field {
	padding: 8px 16px;
	border-radius: 21px;
	border: 1px solid #aaaaaa;
	outline-color: #004d58;
	font-family: 'graphik_regular';
	width: 100%;
}
