.description-wrapper {
	background: #a1e7d733;
	display: flex;
	justify-content: center;
	width: 100%;
}

.description {
	max-width: 1200px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 40px;
	padding: 80px 24px;
	width: 100%;
}

.description .card {
	background: #fbfdfc;
	max-width: 450px;
	padding: 48px;
	border-radius: 30px;
}

.description .card:first-child {
	justify-self: end;
}
.description .card:last-child {
	justify-self: start;
}

.button {
	grid-column: 1 / 3;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

@media screen and (max-width: 768px) {
	.description {
		grid-template-columns: auto;
		grid-template-rows: auto auto auto;
		justify-content: center;
	}

	.description .card {
		grid-column: 1 / 2;
	}

	.button {
		grid-column: 1 / 2;
	}
}

.card.dark {
	background-color: #004d58;
	color: #fbfdfc;
}

.card.dark * {
	color: #fbfdfc;
}

.card h2 {
	margin-bottom: 0.3em;
}

.inline-icon{
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 20px;
}

.inline-icon p {
	margin: 0;
}