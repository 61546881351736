.about-us-wrapper {
	padding: 80px 24px;
}

.about-us {
	max-width: 650px;
	margin: auto;
}

.about-us h2 {
	text-align: center;
	margin-bottom: 0.3em;
}

.about-us p {
	text-align: center;
	margin-bottom: 0.3em;
}

.about-us .center {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}
