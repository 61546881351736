.flex-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.flex-mobile-column {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
	.flex-mobile-column {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}
	.flex-mobile-column {
		flex-direction: column;
		align-items: stretch;
	}
}

.flex-start {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.no-wrap {
	flex-wrap: nowrap;
}

.grid-1-col {
	display: grid;
	grid-template-columns: 1fr;
	align-items: end;
}

.grid-2-col {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: end;
	justify-items: center;
}

.grid-2-col-asymmetric {
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: end;
	justify-items: center;
}

.grid-2-mobile {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-items: start;
}

@media screen and (max-width: 768px) {
	.grid-2-mobile {
		grid-template-columns: 1fr;
	}
	.grid-2-col-asymmetric {
		grid-template-columns: 1fr;
		justify-items: end;
	}
}

.grid-stretch{
	justify-items: stretch;
	align-items: stretch;
}

.grid-start{
	justify-items: start;
	align-items: start;
}

.grid-center{
	justify-items: center;
	align-items: center;
}

.gap-10 {
	gap: 10px;
}

.gap-20 {
	gap: 20px;
}

.gap-30 {
	gap: 30px;
}

.gap-40 {
	gap: 40px;
}

.gap-50 {
	gap: 50px;
}

.gap-60 {
	gap: 60px;
}

.gap-10-20 {
	gap: 10px 20px;
}

.gap-20-10 {
	gap: 20px 10px;
}

.gutter-10 {
	margin-bottom: 10px !important;
}

.gutter-20 {
	margin-bottom: 20px !important;
}

.w-1200 {
	width: 1152px;
	max-width: calc(100% - 48px);
}

@media screen and (max-width: 599px) {
	.w-1200 {
		max-width: calc(100% - 32px);
	}
}