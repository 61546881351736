input[type='checkbox'] {
	transform: scale(2);
	margin: 10px;
	margin: 10px 20px 10px 10px; /* top, right, bottom, left */
	cursor: pointer;
	filter: hue-rotate(55deg) brightness(1) saturate(0.8) contrast(1); /* checkbox blue to #8c5cbc */
}

.checkbox-label {
	font-family: agrandir;
	color: #004d58;
}

/* Prevent cursor being `text` between checkboxes */
body {
	cursor: default;
}
