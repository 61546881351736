.benefits-wrapper {
	box-sizing: border-box;
	width: 100%;
	height: 70vh;
	max-height: 1080px;
	background-size: cover;
	background-position: top center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 80px 24px;
}

.benefits {
	max-width: 1200px;
	display: grid;
	grid-template-columns: 3fr 2fr 3fr;
	justify-items: center;
	align-items: center;
	gap: 20px;
}

.benefits .pills {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	gap: 10px;
}

@media screen and (max-width: 768px) {
	.benefits-wrapper {
		height: auto;
		max-height: none;
	}

	.benefits {
		grid-template-columns: 1fr;
	}
}
