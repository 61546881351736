.testimonials-wrapper {
	background: #a1e7d7;
	padding: 80px 24px 120px 24px;
	overflow: hidden;
	display: flex;
	justify-content: center;
}

.testimonials {
	position: relative;
	inset: 0;
	width: 100%;
	max-width: 1270px;
}

.testimonial-cards {
	width: max-content;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	--margin-testimonial-card: 20px;
	gap: var(--margin-testimonial-card);
	margin-right: var(--margin-testimonial-card);
}

.testimonial-cards .card {
	max-width: 408px;
	min-height: 552px;
	background: #e9f9f5;
	border-radius: 30px;
}

.testimonial-cards .image {
	width: 100%;
	height: 224px;
	border-radius: 30px 30px 0 0;
}

.testimonial-cards .card .text {
	padding: 48px;
}

.testimonial-cards .card .text .large {
	/* font-size: 20px; */
	font-size: 16px;
}

.mobile .testimonial-cards .card .text .large {
	/* font-size: 20px; */
	font-size: 14px;
}

.testimonial-cards .card .text .noGutter {
	margin-bottom: 0;
}

.testimonial-cards .card {
	height: 552px;
}

.inner {
	position: relative;
	width: 100%;
	height: 552px;
}

.wrapper {
	display: flex;
	width: 1270px;
	margin: auto;
	overflow: hidden;
}

.testimonials-wrapper h2 {
	text-align: center;
	margin-bottom: 0.9em;
}

.testimonials-wrapper .illustration {
	position: absolute;
	z-index: 1;
	height: 500px;
	bottom: -150px;
	left: -80px;
}

.mobile .testimonial-cards {
	grid-template-columns: 100%;
	width: 100%;
	gap: 0;
}
.mobile .testimonial-cards .image {
	background-position: top;
}

.mobile .testimonial-cards .card {
	width: 100%;
	overflow: hidden;
	margin-bottom: 24px;
}

p.show-more {
	font-size: 1.2em;
	text-align: center;
	color: #004d58;
	margin-top: 40px;
}

.carousel {
	display: flex;
	/* animation: swipe calc((var(--slide-duration) + var(--stay-duration)) * 5) ease-in-out infinite; */
	/* transition: transform ease 1s; */
}

.testimonials .desktop {
	display: block;
}

.testimonials .mobile {
	display: none;
}

@media screen and (max-width: 900px) {
	.testimonials .desktop {
		display: none;
	}

	.testimonials .mobile {
		display: block;
	}
}

.slider {
	position: absolute;
	bottom: -40px;
	right: 0;
	width: 66%;
	height: 16px;
	appearance: none;
	background-color: #fbfdfc;
	border-radius: 8px;
	outline: none;
	padding: 0;
	margin: 0;
}

input[type='range']::-webkit-slider-thumb {
	width: 100px;
	height: 16px;
	background-color: #004d58;
	border-radius: 8px;
	appearance: none;
	cursor: pointer;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
