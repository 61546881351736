.faq-wrapper {
	background: #a1e7d7;
	padding: 80px 24px 120px 24px;
}

.faq {
	max-width: 800px;
	margin: auto;
}

.faq h2 {
	text-align: center;
	margin-bottom: 0.3em;
}

.faq-container {
	border-bottom: 1px solid #004d58;
}

.faq-container .question {
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: start;
	padding: 8px;
	font-size: 18px;
}

.faq-container .question span {
	position: relative;
	top: 2px;
}

.faq-container.closed .question {
	transition: 0.2s ease;
}

.faq-container.closed .question:hover {
	background: #fbfdfc55;
}

.faq-container .answer {
	padding: 8px;
	font-family: graphik_regular;
}

.opened .icon {
	transition: 0.2s ease;
	transform: rotate(180deg);
}

.closed .icon {
	transition: 0.2s ease;
	transform: rotate(0deg);
}
