.dropdown-select-field {
	display: flex;
	flex-direction: column;
	font-family: agrandir;
	color: rgba(0, 75, 87, 1);
	width: 100%;
}

.dropdown-label {
	margin-bottom: 5px;
	font-weight: 500;
	color: #004d58;
}

.dropdown-select {
	padding: 8px 12px;
	border: 1px solid #ccc;
	border-radius: 100px;
	appearance: none; /* Remove default arrow */
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><polygon points="0,0 10,0 5,5" fill="%23333"/></svg>');
	background-repeat: no-repeat;
	background-position: right 10px center;
	background-size: 10px 5px;
	cursor: pointer;
}

.dropdown-select:disabled {
	background-color: #f5f5f5;
	cursor: not-allowed;
}

/* Optional: Hover and Focus States */
.dropdown-select:hover:not(:disabled) {
	border-color: #888;
}

.dropdown-select:focus {
	outline: none;
	border-color: #555;
}

.dropdown-select .option-text {
	position: relative;
	top: 2px;
	padding: 100px;
	line-height: 2em;
	font-size: 100%;
}

.dropdown-select select {
	display: none; /*hide original SELECT element: */
}

.select-selected:after {
	position: absolute;
	content: '';
	top: 14px;
	right: 10px;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-color: #fff transparent transparent transparent;
}
