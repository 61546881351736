/* Container Styles */
.container {
  display: flex;
  flex-wrap: nowrap;
}

/* Button Styles */
.stepper.button {
  background: #a1e7d7;
  color: #004d58;
  border-radius: 0;
  min-width: 25px;
  font-size: 24px;
  padding: 0 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  align-items: center;
}

/* Field Styles */
.field {
  background: #a1e7d7;
  color: #004d58;
  border: none;
  outline: none;
  height: 50px;
  width: 2em;
  box-sizing: border-box;
  border-radius: 0;
  text-align: center;
  font-size: 24px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
