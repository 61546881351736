.dropdown {
	position: relative;
}

.dropdown .inputField {
	padding: 8px 16px;
	border-radius: 100px;
	border: 1px solid #aaaaaa;
	outline-color: #004d58;
	font-family: graphik_regular;
	width: 100%;
}

.dropdown .autocomplete-active {
	background: #e8def2;
}

.dropdown .autocomplete-items {
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	background: #ffffff;
	border: 1px solid #aaaaaa;
	border-radius: 21px;
	z-index: 99;
}

.dropdown .autocomplete-items div {
	cursor: pointer;
	padding: 10px;
	transition: 0.1s;
}

.dropdown .autocomplete-items div:first-child {
	border-top-left-radius: 21px;
	border-top-right-radius: 21px;
}

.dropdown .autocomplete-items div:last-child {
	border-bottom-left-radius: 21px;
	border-bottom-right-radius: 21px;
}

.dropdown .autocomplete-items div:hover {
	background: #f4eff8;
}
