.intro-wrapper {
	background: #a1e7d7;
	padding: 40px 24px 80px 24px;
}

.intro {
	max-width: 1200px;
	margin: auto;
}

.intro-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 100px 40px;
}

.intro-grid .stats {
	grid-area: 1/1/2/3;
}

.intro-grid .why {
	grid-area: 2/1/2/2;
}

.intro-grid .values {
	grid-area: 2/2/3/3;
}

@media screen and (max-width: 768px) {
	.intro-grid {
		grid-template-columns: 1fr;
		justify-items: center;
		gap: 60px;
	}

	.intro-grid .stats {
		grid-area: initial;
	}

	.intro-grid .why {
		grid-area: initial;
	}

	.intro-grid .values {
		grid-area: initial;
	}
}

.stats {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	gap: 40px;
}

.why .card {
	background: #fbfdfc;
	max-width: 450px;
	padding: 48px;
	border-radius: 30px;
}

.values div {
	display: grid;
	grid-template-columns: auto auto;
	justify-content: center;
	align-items: center;
	gap: 20px 10px;
}

p.big {
	font-size: 32px;
	text-align: center;
}

p.small {
	font-size: 16px;
	text-align: center;
}

.card p {
	margin-bottom: 20px;
}

.intro h2 {
	margin-bottom: 0.3em;
}

.center {
	display: flex;
	justify-content: center;
}
