.action-button {
	border-radius: 100px;
	font-family: agrandir;
	padding: 8px 16px;
	font-size: 16px;
	transition: 0.1s ease;
	width: max-content;
	text-align: center;
}

@media screen and (max-width: 768px) {
	.action-button {
		width: 100%;
	}
}

.action-button-label {
	position: relative;
	top: 2px;
}

.action-button-muted {
}

.button-primary {
	color: #ffffff;
	background: #8c5cbc;
	border: 1px solid #8c5cbc;
}

.button-primary:hover {
	background: hsl(270, 42%, 50%);
	border: 1px solid hsl(270, 42%, 50%);
}

.button-secondary {
	background: #ffffff;
	border: 1px solid #004d58;
	color: #004d58;
}

.button-secondary:hover {
	background: hsl(188, 0%, 95%);
	border: 1px solid #004d58;
	color: #004d58;
}

.button-danger {
	background: #a30000;
	color: #ffffff;
	border: 1px solid #a30000;
}

.button-danger:hover {
	background: hsl(0, 100%, 25%);
	color: #ffffff;
	border: 1px solid hsl(0, 100%, 25%);
}

.button-muted {
	color: hsl(0, 0%, 50%);
}

.button-muted:hover {
	color: #004d58;
}

.icon svg {
	display: inline;
}

.icon-offset-right {
	position: relative;
	right: -4px;
}

.icon-offset-left {
	position: relative;
	left: -4px;
}
